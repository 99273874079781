var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading,
      "rounded": "sm"
    }
  }, [_c('div', [_c('b-card', {
    staticClass: "overflow-hidden",
    attrs: {
      "bg-variant": "primary"
    }
  }, [_c('b-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('div', {
    staticStyle: {
      "padding": "10px"
    }
  }, [_c('h3', {
    staticClass: "text-white"
  }, [_vm._v("SNBP")])])]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', [_c('b-media', {
    staticClass: "mb-2",
    attrs: {
      "vertical-align": "top"
    },
    scopedSlots: _vm._u([{
      key: "aside",
      fn: function fn() {
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-icon rounded-circle",
          attrs: {
            "size": "sm",
            "variant": "dark",
            "disabled": ""
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "UserIcon"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('span', {
    staticStyle: {
      "font-size": "1.1rem"
    }
  }, [_vm._v("Total Durasi Video")]), _c('br'), _c('b', {
    staticClass: "text-primary",
    staticStyle: {
      "font-size": "1rem"
    }
  }, [_vm._v("1 jam")])])], 1), _c('b-col', [_c('b-media', {
    staticClass: "mb-2",
    attrs: {
      "vertical-align": "top"
    },
    scopedSlots: _vm._u([{
      key: "aside",
      fn: function fn() {
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-icon rounded-circle",
          attrs: {
            "size": "sm",
            "variant": "dark",
            "disabled": ""
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "FileTextIcon"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('span', [_vm._v("Jumlah Total Video")]), _c('br'), _c('b', {
    staticClass: "text-primary"
  }, [_vm._v("10 video")])])], 1)], 1)], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "lg": "6",
      "cols": "12"
    }
  }, [_c('b-card', {
    staticClass: "shadow",
    attrs: {
      "border-variant": "primary"
    }
  }, [_c('b-card-header', {
    staticClass: "d-flex justify-content-between align-items-center",
    attrs: {
      "bg-variant": "primary",
      "text-variant": "white"
    }
  }, [_c('div', [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v("Video Pembelajaran")])]), _c('feather-icon', {
    attrs: {
      "icon": "PlayCircleIcon",
      "size": "20"
    }
  })], 1), _c('b-card-body', _vm._l(_vm.videos, function (video, i) {
    return _c('div', {
      key: i,
      staticClass: "mb-2"
    }, [_c('div', {
      staticClass: "d-flex justify-content-between align-items-center"
    }, [_c('h5', {
      staticClass: "mb-1"
    }, [_vm._v(_vm._s(video.title))]), _c('b-button', {
      attrs: {
        "variant": "link",
        "size": "sm"
      },
      on: {
        "click": function click($event) {
          return _vm.toggleCollapse(video);
        }
      }
    }, [_c('feather-icon', {
      staticStyle: {
        "color": "black"
      },
      attrs: {
        "icon": video.isOpen ? 'ChevronUpIcon' : 'ChevronDownIcon'
      }
    })], 1)], 1), _c('p', {
      staticClass: "mb-1",
      staticStyle: {
        "font-size": "1rem"
      }
    }, [_vm._v(" Panduan visual untuk membantu memahami materi. ")]), _c('b-collapse', {
      model: {
        value: video.isOpen,
        callback: function callback($$v) {
          _vm.$set(video, "isOpen", $$v);
        },
        expression: "video.isOpen"
      }
    }, [_c('video', {
      staticStyle: {
        "width": "100%",
        "border-radius": "10px"
      },
      attrs: {
        "src": video.url,
        "controls": ""
      }
    })])], 1);
  }), 0)], 1)], 1), _c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "lg": "6",
      "cols": "12"
    }
  }, [_c('b-card', {
    staticClass: "shadow",
    attrs: {
      "border-variant": "primary"
    }
  }, [_c('b-card-header', {
    staticClass: "d-flex justify-content-between align-items-center",
    attrs: {
      "bg-variant": "primary",
      "text-variant": "white"
    }
  }, [_c('div', [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v("Penjelasan Materi")])]), _c('feather-icon', {
    attrs: {
      "icon": "BookOpenIcon",
      "size": "20"
    }
  })], 1), _c('b-card-body', _vm._l(_vm.explanations, function (explanation, i) {
    return _c('div', {
      key: i,
      staticClass: "mb-2"
    }, [_c('div', {
      staticClass: "d-flex justify-content-between align-items-center"
    }, [_c('h5', {
      staticClass: "mb-2"
    }, [_vm._v(_vm._s(explanation.title))]), _c('b-button', {
      attrs: {
        "variant": "link",
        "size": "sm"
      },
      on: {
        "click": function click($event) {
          return _vm.toggleCollapse(explanation);
        }
      }
    }, [_c('feather-icon', {
      staticStyle: {
        "color": "black"
      },
      attrs: {
        "icon": explanation.isOpen ? 'ChevronUpIcon' : 'ChevronDownIcon'
      }
    })], 1)], 1), _c('p', {
      staticClass: "mb-1",
      staticStyle: {
        "font-size": "1.1rem"
      }
    }, [_vm._v(" Panduan visual untuk membantu memahami materi. ")]), _c('b-collapse', {
      model: {
        value: explanation.isOpen,
        callback: function callback($$v) {
          _vm.$set(explanation, "isOpen", $$v);
        },
        expression: "explanation.isOpen"
      }
    }, [_c('p', {
      staticClass: "text-muted",
      staticStyle: {
        "font-size": "1rem"
      }
    }, [_vm._v(_vm._s(explanation.content))])])], 1);
  }), 0)], 1)], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }