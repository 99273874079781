<template>
  <b-overlay :show="loading" rounded="sm">
    <div>
      <!-- Header Section -->
      <b-card class="overflow-hidden" bg-variant="primary">
        <b-row no-gutters>
          <b-col md="6">
            <div style="padding: 10px">
              <h3 class="text-white">SNBP</h3>
            </div>
          </b-col>
          <b-col md="6">
            <b-card>
              <b-row>
                <b-col>
                  <b-media vertical-align="top" class="mb-2">
                    <template #aside>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        size="sm"
                        variant="dark"
                        class="btn-icon rounded-circle"
                        disabled
                      >
                        <feather-icon icon="UserIcon" />
                      </b-button>
                    </template>
                    <span style="font-size: 1.1rem;">Total Durasi Video</span>
                    <br />
                    <b class="text-primary" style="font-size: 1rem;">1 jam</b>
                  </b-media>
                </b-col>
                <b-col>
                  <b-media vertical-align="top" class="mb-2">
                    <template #aside>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        size="sm"
                        variant="dark"
                        class="btn-icon rounded-circle"
                        disabled
                      >
                        <feather-icon icon="FileTextIcon" />
                      </b-button>
                    </template>
                    <span>Jumlah Total Video</span>
                    <br />
                    <b class="text-primary">10 video</b>
                  </b-media>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-card>

      <!-- Video and Explanation Section -->
      <b-row>
        <!-- Video Section -->
        <b-col lg="6" cols="12" class="mb-3">
          <b-card border-variant="primary" class="shadow">
            <b-card-header
              class="d-flex justify-content-between align-items-center"
              bg-variant="primary"
              text-variant="white"
            >
              <div>
                <h4 class="mb-2">Video Pembelajaran</h4>
                <!-- Subjudul -->
              </div>
              <feather-icon icon="PlayCircleIcon" size="20"></feather-icon>
            </b-card-header>
            <b-card-body>
              <div v-for="(video, i) in videos" :key="i" class="mb-2">
                <div class="d-flex justify-content-between align-items-center">
                  <h5 class="mb-1">{{ video.title }}</h5>
                  <!-- Toggle button -->
                  <b-button
                    @click="toggleCollapse(video)"
                    variant="link"
                    size="sm"
                  >
                    <feather-icon
                      :icon="video.isOpen ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                      style="color: black"
                    />
                  </b-button>
                </div>
                <p class="mb-1" style="font-size: 1rem">
                  Panduan visual untuk membantu memahami materi.
                </p>
                <!-- Collapsible video section -->
                <b-collapse v-model="video.isOpen">
                  <video
                    :src="video.url"
                    controls
                    style="width: 100%; border-radius: 10px"
                  ></video>
                </b-collapse>
              </div>
            </b-card-body>
          </b-card>
        </b-col>

        <!-- Explanation Section -->
        <b-col lg="6" cols="12" class="mb-4">
          <b-card border-variant="primary" class="shadow">
            <b-card-header
              class="d-flex justify-content-between align-items-center"
              bg-variant="primary"
              text-variant="white"
            >
              <div>
                <h4 class="mb-2">Penjelasan Materi</h4>
                <!-- Subjudul -->
              </div>
              <feather-icon icon="BookOpenIcon" size="20"></feather-icon>
            </b-card-header>
            <b-card-body>
              <div
                v-for="(explanation, i) in explanations"
                :key="i"
                class="mb-2"
              >
                <div class="d-flex justify-content-between align-items-center">
                  <h5 class="mb-2">{{ explanation.title }}</h5>
                  <!-- Toggle button -->
                  <b-button
                    @click="toggleCollapse(explanation)"
                    variant="link"
                    size="sm"
                  >
                    <feather-icon
                      :icon="
                        explanation.isOpen ? 'ChevronUpIcon' : 'ChevronDownIcon'
                      "
                      style="color: black"
                    />
                  </b-button>
                </div>
                <p class="mb-1" style="font-size: 1.1rem">
                  Panduan visual untuk membantu memahami materi.
                </p>
                <!-- Collapsible explanation section -->
                <b-collapse v-model="explanation.isOpen">
                  <p class="text-muted" style="font-size: 1rem;">{{ explanation.content }}</p>
                </b-collapse>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BCollapse,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BOverlay,
    BButton,
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BCollapse,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      videos: [
        {
          title: "Video 1: Pengenalan",
          url: "path_to_video_1.mp4",
          isOpen: false,
        },
        {
          title: "Video 2: Dasar-dasar",
          url: "path_to_video_2.mp4",
          isOpen: false,
        },
      ],
      explanations: [
        {
          title: "Penjelasan 1",
          content:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. A culpa delectus fugiat eaque illum obcaecati eius ut temporibus aperiam suscipit fugit voluptatem est labore velit laudantium, non quaerat pariatur facere.",
          isOpen: false,
        },
        {
          title: "Penjelasan 2",
          content:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. A culpa delectus fugiat eaque illum obcaecati eius ut temporibus aperiam suscipit fugit voluptatem est labore velit laudantium, non quaerat pariatur facere.",
          isOpen: false,
        },
      ],
    };
  },
  methods: {
    toggleCollapse(item) {
      item.isOpen = !item.isOpen;
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

* {
  font-family: "Open Sans", sans-serif;
} 

.shadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.text-muted {
  color: #6c757d !important;
}
</style>
