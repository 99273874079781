<template>
  <div class="">
    <b-overlay :show="loading">
      <!-- Header filter -->
      <b-row class="mb-2 align-items-center">
        <b-col
          sm="12"
          md="6"
          lg="8"
          class="d-flex justify-content-sm-center justify-content-md-start"
        >
          <b-form-group>
            <div class="d-flex flex-row align-items-center">
              <label class="mr-1"><strong>Kategori</strong></label>
              <b-form-select
                v-model="filteredBy"
                :options="paketCategories"
              ></b-form-select>
            </div>
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="4"
          class="d-flex justify-content-sm-center justify-content-md-end"
        >
          <b-form-group class="blog-search" style="width: 400px">
            <b-input-group class="input-group-merge">
              <b-form-input
                id="search-input"
                v-model="q"
                placeholder="Cari Jadwal Try Out..."
              />
              <b-input-group-append class="cursor-pointer" is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- / -->
      <!-- Data rekap -->
      <b-row class="align-items-center justify-content-center">
        <b-col sm="12" md="4">
          <div class="card">
            <div class="card-body p-1">
              <section class="d-flex align-items-center">
                <div class="bg-danger p-1 bg-opacity-10 rounded">
                  <feather-icon
                    icon="BookOpenIcon"
                    class="text-white"
                    size="20"
                  />
                </div>
                <div class="ml-1">
                  <small id="tryout_saya" class="d-block">
                    Try Out Saya
                    <feather-icon
                      icon="InfoIcon"
                      style="margin-left: 5px"
                    ></feather-icon>
                  </small>
                  <h1>
                    <strong>{{ rekap.tryout_saya }}</strong>
                  </h1>
                  <b-tooltip
                    triggers="hover"
                    target="tryout_saya"
                    title="Tryout saya adalah tryout-tryout yang sedang/sudah kamu kerjakan"
                  />
                </div>
              </section>
            </div>
          </div>
        </b-col>
        <!-- <b-col sm="12" md="4">
          <div class="card">
            <div class="card-body p-1">
              <section class="d-flex align-items-center">
                <div class="bg-danger p-1 bg-opacity-10 rounded">
                  <feather-icon icon="XCircleIcon" class="text-white" size="20" />
                </div>
                <div class="ml-1">
                  <small id="tryout_tersedia" class="d-block">
                    Try Out Tersedia
                    <feather-icon icon="InfoIcon" style="margin-left: 5px"></feather-icon>
                  </small>
                  <h1>
                    <strong>{{ rekap.tryout_tersedia }}</strong>
                  </h1>
                  <b-tooltip triggers="hover" target="tryout_tersedia" title="Tryout tersedia adalah tryout-tryout yang belum sama sekali kamu kerjakan" />
                </div>
              </section>
            </div>
          </div>
        </b-col> -->
      </b-row>
      <!-- / -->
      <b-row class="match-height" v-if="data.length > 0">
        <b-col md="6" lg="4" v-for="(item, i) in data" :key="i">
          <div class="card">
            <div class="card text-white">
              <img
                :src="apiFile + item.thumbnail"
                alt="Avatar"
                class="image"
                style="width: 100%; height: 170px; object-fit: cover"
              />
              <div class="overlay"></div>
              <div class="card-img-overlay">
                <h5 class="card-title text-white" style="margin-top: 60px">
                  <b>{{ item.name }}</b>
                </h5>
                <div class="row">
                  <div class="col">{{ item.sum_subtest }} Subtes</div>
                  <div class="col">
                    <b-button
                      variant="light"
                      class="btn-sm float-right"
                      style="cursor: default; border-radius: 10px"
                    >
                      <feather-icon
                        icon="UserIcon"
                        class="mr-50 text-primary"
                      />
                      <span class="align-middle">
                        <b>{{ item.sum_quota }}</b>
                        /
                      </span>
                      <span class="align-middle text-dark">
                        {{ item.max_quota == 0 ? "∞" : item.max_quota }}
                      </span>
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="ml-2 mr-2 mb-2">
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center text-body">
                  <feather-icon icon="ClockIcon" class="mr-50" />
                  <small class="font-weight-bold">Waktu Mulai</small>
                </div>
                <div class="d-flex align-items-center text-body">
                  <feather-icon icon="ClockIcon" class="mr-50" />
                  <small class="font-weight-bold">Waktu Berakhir</small>
                </div>
              </div>
              <div
                class="d-flex justify-content-between align-items-center mt-1"
              >
                <div class="d-flex align-items-center text-body">
                  <small
                    v-if="item.info_start"
                    class="font-weight-bold"
                    style="color: black"
                  >
                    {{ getTanggal(item.info_start) }} WIB
                  </small>
                  <small v-else class="text-danger font-weight-bold">
                    <i>Tidak ada</i>
                  </small>
                </div>
                <div class="d-flex align-items-center text-body">
                  <small
                    v-if="item.info_end"
                    class="font-weight-bold"
                    style="color: black"
                  >
                    {{ getTanggal(item.info_end) }} WIB
                  </small>
                  <small v-else class="text-danger font-weight-bold">
                    <i>Tidak ada</i>
                  </small>
                </div>
              </div>
              <div class="" v-if="item.jadwal_id != null">
                <div class="">
                  <div
                    class=""
                    v-if="item.in_schedule_detail.text == 'belum_berjalan'"
                  >
                    <b-button
                      class="mt-2"
                      variant="outline-dark"
                      disabled
                      block
                    >
                      Belum Berjalan
                    </b-button>
                  </div>
                  <div
                    class=""
                    v-else-if="item.in_schedule_detail.text == 'sudah_berakhir'"
                  >
                    <b-button
                      class="mt-2"
                      variant="outline-dark"
                      disabled
                      block
                    >
                      Mulai Kerjakan
                    </b-button>
                  </div>
                  <div class="" v-else>
                    <b-button
                      class="mt-2"
                      variant="outline-dark"
                      block
                      @click="buka(item)"
                    >
                      Mulai Kerjakan
                    </b-button>
                  </div>
                </div>
              </div>
              <div class="" v-else>
                <b-button class="mt-2" variant="outline-dark" block disabled>
                  Mulai Kerjakan
                </b-button>
              </div>
            </div>
          </div>
          <b-modal
            header-bg-variant="light-warning"
            v-model="Modal"
            id="modal-tambah"
            centered
            title="Peringatan!"
            hide-footer
          >
            <p>
              Kamu belum mengisi profil diri kamu! Silahkan isi dengan mengklik
              tombol dibawah ini
            </p>
            <center>
              <b-button variant="primary" :to="{ name: 'profil' }">
                Profil
              </b-button>
            </center>
          </b-modal>
        </b-col>
      </b-row>
      <Blank v-else />
    </b-overlay>
  </div>
</template>

<script>
import {
  BOverlay,
  BTooltip,
  BModal,
  BBadge,
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BImg,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BLink,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Blank from "./Blank.vue";

export default {
  components: {
    BOverlay,
    BTooltip,
    BModal,
    BBadge,
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BRow,
    BCol,
    BLink,
    BImg,
    Blank,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      rekap: {
        tryout_saya: 0,
        tryout_tersedia: 0,
      },
      loading: false,
      filteredBy: null,
      paketCategories: [],
      data: [],
      categoryId: null,
      Modal: false,
      q: null,
    };
  },
  watch: {
    filteredBy(categoryId) {
      if (categoryId) {
        this.categoryId = categoryId == "all" ? null : categoryId;
        this.getData();
      }
    },
    q: {
      handler(value) {
        const tryouts = Object.assign([], this.$store.state.tryout.tryoutSaya);

        if (this.q == "") {
          this.q = null;
          this.data = tryouts;
          this.rekap.tryout_saya = tryouts.length;
        } else {
          const filteredTryouts = tryouts.filter((tryout) => {
            const keyword = this.q.toLowerCase();
            return tryout.name.toLowerCase().indexOf(keyword) > -1;
          });
          this.data = filteredTryouts;
          this.rekap.tryout_saya = filteredTryouts.length;
        }
      },
      deep: true,
    },
  },
  methods: {
    buka(item) {
      this.loading = true;
      if (this.user.detail == null) {
        this.Modal = true;
      } else {
        const params = {
          paket_id: item.id,
        };

        this.$store.commit("tryout/SET_DETAIL", item);
        this.$router.push({
          name: "tryout-detail",
          query: { j: item.jadwal_id },
        });
      }
    },
    getData() {
      this.loading = true;
      let params = {
        search: this.q,
        is_confirmed: 1,
      };

      if (this.categoryId) {
        params.category_id = this.categoryId;
      }
      this.$store
        .dispatch("tryout/myPaket", params)
        .then((res) => {
          this.data = res.data?.data;
          this.$store.commit("tryout/SET_TRYOUT_SAYA", this.data);
          this.rekap.tryout_saya = this.data.filter(
            (tryout) => tryout.is_confirmed
          ).length;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error `,
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.message,
            },
          });
        });
    },
    async getPaketCategories() {
      try {
        this.loading = true;
        const response = await this.$store.dispatch(
          "paketTryout/indexKategoriPaket"
        );
        const paketCategories = response.data?.data;
        this.paketCategories.push({
          text: "-- Pilih Kategori Paket --",
          value: null,
        });
        this.paketCategories.push({
          text: "Semua Kategori",
          value: "all",
        });
        this.paketCategories.push(
          ...paketCategories.map((category) => ({
            text: category.name,
            value: category.id,
          }))
        );

        this.getData();
      } catch (e) {
        this.loading = false;
        this.displayError(e);
        return false;
      }
    },
  },
  created() {
    this.getPaketCategories();
  },
};
</script>

<style>
.image {
  display: block;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  background: rgb(209, 30, 11);
  background: linear-gradient(
    0deg,
    rgba(209, 30, 11, 0.7259278711484594) 23%,
    rgba(230, 199, 25, 0) 73%
  );
  width: 100%;
  height: 170px;
}
</style>